import { InboxOutlined } from '@ant-design/icons';
import { message, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { useField } from 'formik';
import { useParams } from 'react-router-dom';
import { useGetSalesOrderQuery } from 'redux/services/useMamaBearService';

export const AttachmentUpload = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [{ value }, __, { setValue }] = useField<RcFile[]>('attachments');
  const { id } = useParams();
  const { data: salesOrder } = useGetSalesOrderQuery({ id: id as string });
  /* ******************** Functions ******************** */

  const editedNames = salesOrder?.attachments?.map((attachment) => attachment.name);

  const props: UploadProps = {
    multiple: true,
    customRequest: ({ file }) => {
      const fileToUpload = file as RcFile;

      if (value.find((uploadedFile) => uploadedFile.name === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been added.`);
      else if (editedNames?.find((uploadedFile) => uploadedFile === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been uploaded.`);
      else setValue([...value, file as RcFile].filter((file) => !editedNames?.includes(file.name)));
    },
    onRemove(file) {
      setValue(value.filter((item) => item.uid !== file.uid));
    }
  };

  return (
    <Dragger {...props} defaultFileList={[]} listType="picture" height={200} style={{ display: 'inline-block', width: '100%' }} fileList={value.filter((value) => !editedNames?.includes(value.name))}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ color: '#5867dd' }} />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  );
};
