import { Col, Divider, List, Row } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import NoResults from 'components/atoms/noResults/noResults';
import RecoverActions from 'components/atoms/RecoverActions';
import RecoverSalesOrderCard from 'components/atoms/RecoverSalesOrderCard';
import { SalesOrderFilters } from 'components/molecules/SalesOrderFilters';
import { usePrevious } from 'hooks/usePrevious';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderModel } from 'models/SalesOrderModel';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetDeletedSalesOrdersQuery } from 'redux/services/useMamaBearService';

const styles: InlineStylesModel = {
  divider: {
    margin: '9px 0px'
  }
};

/* ****************** interfaces ****************** */

export const Recover = (): JSX.Element => {
  const { acuityContext } = useSelector((state: ReduxState) => state.app);

  const {
    selectedCompanyDataAreaId: dataAreaId,
    filterCreatedByUserFullName,
    filterCreatedDateTime,
    filterCustomerPO,
    filterDraftId,
    filterQuoteId,
    filterSalesOrderNumber,
    filterSalesPerson,
    filterStartDate,
    filterEndDate,
    filterStatus
  } = useSelector((state: ReduxState) => state.salesOrders);

  const { filterCustomerId, filterCustomerName } = useSelector((state: ReduxState) => state.customerSelectionFilters);

  const [salesOrders, _setSalesOrders] = useState<SalesOrderModel[]>([]);
  const [offset, _setOffset] = useState(0);
  const [isLoadingMore, _setIsLoadingMore] = useState(false);
  const [isNewSearch, _setIsNewSearch] = useState(false);
  const [totalCount, _setTotalCount] = useState<number | null>(null);
  const [resetResults, _setResetResults] = useState(false);

  const prevDataAreaId = usePrevious(dataAreaId);
  const prevFilterCreatedByUserFullName = usePrevious(filterCreatedByUserFullName);
  const prevFilterCreatedDateTime = usePrevious(filterCreatedDateTime);
  const prevFilterCustomerId = usePrevious(filterCustomerId);
  const prevFilterCustomerName = usePrevious(filterCustomerName);
  const prevFilterCustomerPO = usePrevious(filterCustomerPO);
  const prevFilterDraftId = usePrevious(filterDraftId);
  const prevFilterQuoteId = usePrevious(filterQuoteId);
  const prevFilterSalesOrderNumber = usePrevious(filterSalesOrderNumber);
  const prevFilterSalesPerson = usePrevious(filterSalesPerson);
  const prevFilterStartDate = usePrevious(filterStartDate);
  const prevFilterEndDate = usePrevious(filterEndDate);
  const prevFilterStatus = usePrevious(filterStatus);

  /* ****************** API/Hooks ****************** */

  const getSalesOrdersParams = {
    dataAreaId: dataAreaId ? dataAreaId : '',
    divisionId: acuityContext ? acuityContext.selectedCustomer.id : undefined,
    createdByUserNameContains: filterCreatedByUserFullName !== null ? filterCreatedByUserFullName : undefined,
    createdDateTime: filterCreatedDateTime !== null ? filterCreatedDateTime : undefined,
    customerIdContains: filterCustomerId !== null ? filterCustomerId : undefined,
    customerNameContains: filterCustomerName !== null ? filterCustomerName : undefined,
    customerPurchaseOrderNumberContains: filterCustomerPO !== null ? filterCustomerPO : undefined,
    draftIdContains: filterDraftId !== null ? filterDraftId : undefined,
    quoteIdContains: filterQuoteId !== null ? filterQuoteId : undefined,
    salesOrderNumberContains: filterSalesOrderNumber !== null ? filterSalesOrderNumber : undefined,
    salesPersonNameContains: filterSalesPerson !== null ? filterSalesPerson : undefined,
    startDate: filterStartDate !== null ? filterStartDate : undefined,
    endDate: filterEndDate !== null ? filterEndDate : undefined,
    status: filterStatus !== null ? filterStatus : undefined,
    orderByField: 'createdDateTime',
    orderByDirection: 'descending',
    offset
  };

  const {
    data: responseDeletedSalesOrders,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetDeletedSalesOrdersQuery(getSalesOrdersParams, {
    skip: !dataAreaId
  });

  return (
    <Row>
      <Col span={24}>
        <SalesOrderFilters />
      </Col>
      <Col span={24}>
        <RecoverActions responseDeletedSalesOrders={responseDeletedSalesOrders?.data} />
      </Col>
      <Divider style={styles.divider} />
      {responseDeletedSalesOrders?.data.length !== 0 ? (
        <Col span={24}>
          <List
            loading={{
              indicator: <BlockLoader direction="loader loader--slideUp" />,
              spinning: isLoading,
              size: 'large',
              wrapperClassName: isLoading ? 'list-loading' : ''
            }}
            grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 4 }}
            dataSource={responseDeletedSalesOrders?.data}
            renderItem={(item): JSX.Element => <RecoverSalesOrderCard salesOrder={item} />}
            rowKey={(item): string => item.id}
            style={{ overflow: 'hidden' }}
          />
        </Col>
      ) : (
        <NoResults errorMessage="No Results Were Found..." />
      )}
    </Row>
  );
};
