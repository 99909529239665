import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReleasedProductModel } from 'models/ReleasedProductModel';
import { useEffect, useState } from 'react';

export const useLocalProduct = (itemId: string): { data?: ReleasedProductModel; error?: FetchBaseQueryError; exists: boolean; clearLocalData: () => void; isError: boolean; isLoading: boolean } => {
  const [data, _setData] = useState<ReleasedProductModel | undefined>(undefined);
  const [error, _setError] = useState<FetchBaseQueryError | undefined>(undefined);
  const [isError, _setIsError] = useState<boolean>(false);
  const [exists, _setExists] = useState<boolean>(true);
  const [isLoading, _setIsLoading] = useState(false);

  useEffect(() => {
    _setIsLoading(true);
    const rawData = sessionStorage.getItem(itemId);
    const jsonData = rawData ? JSON.parse(rawData) : undefined;

    _setExists(!!rawData);
    _setData(jsonData && 'productNumber' in jsonData ? jsonData : undefined);
    _setError(jsonData && 'error' in jsonData ? jsonData : undefined);
    _setIsError(jsonData && 'error' in jsonData);
    _setIsLoading(false);
  }, [itemId]);

  const clearLocalData = (): void => {
    sessionStorage.removeItem(itemId);
    _setExists(false);
    _setIsError(false);
    _setData(undefined);
    _setError(undefined);
  };

  return { data, error, exists, clearLocalData, isError, isLoading };
};
