import { Typography } from 'antd';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderModel } from 'models/SalesOrderModel';
import { Fragment, useEffect } from 'react';

export const InventoryReservationMethodInput = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<SalesOrderModel>();
  const styles: InlineStylesModel = {
    formItem: {
      marginBottom: 10,
      width: '100%'
    },
    formItemCol: {
      marginRight: '16px',
      maxHeight: '42px'
    },
    titleColor: {
      color: '#2F5596',
      marginTop: 10
    }
  };

  useEffect(() => {
    if (!values.inventoryReservationMethod) setFieldValue('inventoryReservationMethod', 'Automatic');
  }, []);

  return (
    <Fragment>
      <Typography.Title level={5} style={styles.titleColor}>
        Reservation Setting
      </Typography.Title>
      <SelectInput allowClear selectOptions={['Automatic', 'Manual']} fieldName="inventoryReservationMethod" />
    </Fragment>
  );
};
