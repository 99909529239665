import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Table, message } from 'antd';
import { MessageType } from 'antd/lib/message';
import { ColumnType } from 'antd/lib/table';
import { SublineEditableCell } from 'components/atoms/newCells/SublineEditableCell';
import { useField } from 'formik';
import { ProductSubType } from 'models/ProductSubType';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FixedType } from 'rc-table/lib/interface';
import React, { LegacyRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';

interface Props {
  subLines: ProductSubType[];
  product?: SalesOrderLineItemModel;
  index: number;
}
interface EditableColumnType extends ColumnType<ProductSubType> {
  editable?: boolean;
  fixed?: FixedType;
}

export const SublineTable: React.FC<Props> = ({ subLines, index }) => {
  const [ref, { height }] = useMeasure();

  const [editingKey, setEditingKey] = useState('');
  const [idx, setIdx] = useState<number | undefined>(undefined);
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');
  const [a, { value }, { setValue }] = useField<ProductSubType>(`lines[${index}].subLines[${idx}]`);
  const [b, { value: lineValue }, { setValue: setLineValue }] = useField<SalesOrderLineItemModel>(`lines[${index}]`);

  const isOverview = pathname.includes('quote');

  const isEditing = (record: ProductSubType): boolean => record.productNumber === editingKey;

  const save = async (record: ProductSubType): Promise<void> => {
    try {
      const row = (await form.validateFields()) as ProductSubType;

      setValue({ ...record, ...row });
      setEditingKey('');
      form.resetFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (record: ProductSubType): void | MessageType => {
    if (!record || !record.productNumber) return message.error('Record cannot be found, the team has been notified');

    setLineValue({ ...lineValue, subLines: lineValue.subLines.filter((child) => child.productNumber !== record.productNumber).map((child, idx) => ({...child, lineNumber: lineValue.lineNumber + ((idx + 1)* 0.01)})) });

    setEditingKey('');
  };

  const edit = (record: ProductSubType): void | MessageType => {
    if (!record.productNumber) return message.error('Cannot find product number, the team has been notified of this issue.');
    if (!editingKey) {
      form.setFieldsValue({
        quantity: record.quantity || 0,
        productNumber: record.productNumber,
        productDescription: record.productDescription || ''
      });
      setEditingKey(record.productNumber);
    } else save(record);
  };
  const cols: EditableColumnType[] = [
    {
      title: 'Line #',
      width: 32,
      render: (_: string, record: ProductSubType, subLineIndex: number): JSX.Element => {

        return <div style={{ textAlign: 'left' }}>{record.lineNumber.toFixed(2)}</div>;
      }
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      width: 68,
      editable: true
    },
    {
      title: 'Description',
      dataIndex: 'productDescription',
      key: 'productDescription',
      width: 68,
      editable: true
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 80,
      align: 'right',
      editable: true
    },
    {
      title: 'Action',
      width: '10ch',
      key: 'action',
      align: 'center',
      // fixed: 'right',
      render: (_, record: ProductSubType): JSX.Element => {
        return (
          <Row style={{ width: '100%' }} justify="center" gutter={[5, 5]}>
            <Col>
              {editingKey === record.productNumber ? (
                <Button style={{ borderRadius: '50%' }} onClick={(): void | MessageType => edit(record)} disabled={isView} icon={<CheckOutlined />} />
              ) : (
                <Button style={{ borderRadius: '50%' }} disabled={!!editingKey || isView} onClick={(): void | MessageType => edit(record)} icon={<EditOutlined />} />
              )}
            </Col>
            <Col>
              <Button
                disabled={isView}
                className="product-table__delete-icon"
                onClick={(e): void => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: `Delete ${record.productNumber}`,
                    onOk: () => handleDelete(record),
                    okText: 'Delete',
                    okType: 'danger',
                    content: 'Are you sure you want to delete this product?'
                  });
                }}
                style={{ borderRadius: '50%' }}
                icon={<DeleteOutlined />}
              />
            </Col>
          </Row>
        );
      }
    }
  ];

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: height || 0
  });

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ProductSubType) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: `${col.title}`,
        editing: isView ? false : isEditing(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
        <div ref={ref as LegacyRef<HTMLDivElement>}>
          {isOverview ? (
            <Table
              bordered
              style={{ padding: 16, width: 1000, marginLeft: 65 }}
              size="small"
              indentSize={0}
              pagination={false}
              dataSource={subLines}
              columns={cols.filter((col) => col.key !== 'action')}
            />
          ) : (
            <Table
              bordered
              components={{
                body: {
                  cell: SublineEditableCell
                }
              }}
              rowClassName="editable-row"
              style={{ padding: 16, width: 1000, marginLeft: 65 }}
              size="small"
              indentSize={0}
              pagination={false}
              dataSource={subLines}
              columns={mergedColumns}
              onRow={(record: ProductSubType, index) => ({
                onClick: (): void => {
                  setIdx(index);
                  if (editingKey !== record.productNumber) edit(record);
                },
                onKeyDown: (event): void => {
                  if (event.key.toLocaleLowerCase() === 'enter') {
                    if (editingKey === record.productNumber) save(record);
                  }
                }
              })}
            />
          )}
        </div>
      </animated.div>
    </Form>
  );
};
