import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { User } from 'models/CookieMonsterUserModel';
import { EditUserPayload } from 'models/Users';
import { ReduxState } from 'redux/rootReducer';

/* ****************** Variables ****************** */
const chuckieSueBaseUrl = process.env.REACT_APP_COOKIE_MONSTER_BASE_URL;
const functionsKey = process.env.REACT_APP_COOKIE_MONSTER_HOST_KEY || '';

const baseQuery = fetchBaseQuery({
  baseUrl: chuckieSueBaseUrl,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
    }
    if (endpoint !== 'updateUserProfileImage') headers.set('Content-Type', 'application/json');

    return headers;
  }
});

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (userId) => ({
        url: `users/${userId}`
      }),
      providesTags: ['User']
    }),
    updateUser: builder.mutation<User, { userId: string; payload: EditUserPayload }>({
      query: ({ userId, payload }) => ({
        url: `/users/${userId}`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['User']
    }),
    updateUserProfileImage: builder.mutation<User, { userId: string; payload: FormData }>({
      query: ({ userId, payload }) => ({
        url: `/users/${userId}/profileImage`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['User']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetUserQuery, useGetVersionQuery, useGetApiNameQuery, useUpdateUserMutation, useUpdateUserProfileImageMutation } = userApi;
