import { Button, Descriptions, Row, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import React, { LegacyRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';

interface Props {
  title: string;
  items: { label: string; value: string | null | undefined }[];
  headerColor?: string;
}

export const InfoPanel = ({ title, items, headerColor }: Props): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(true);
  const [ref, { height }] = useMeasure();
  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: visible ? height : 0
  });

  return (
    <div>
      <Row
        onClick={(): void => setVisible(!visible)}
        justify="space-between"
        align="middle"
        style={{ background: headerColor, padding: '0px 10px', width: '100%', marginBottom: visible ? 8 : 0, borderRadius: 5, cursor: 'pointer' }}>
        <Typography.Title level={4} style={{ marginBottom: 0, padding: 12 }}>
          {title}
        </Typography.Title>
        <Button style={{ width: 115 }} type="text" onClick={(): void => setVisible(!visible)} key="show-section" icon={<CaretSpinIcon isOpen={visible} />}>
          {visible ? 'Collapse' : 'Expand'}
        </Button>
      </Row>
      <animated.div style={{ ...{ overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
        {visible && (
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <Descriptions style={{ background: 'white' }} layout="vertical" bordered size={'middle'} column={3}>
              {items.map((item) => (
                <Descriptions.Item key={item.value} label={`${item.label}: `} labelStyle={{ fontSize: 15 }}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        )}
      </animated.div>
    </div>
  );
};
