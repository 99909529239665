import { Form, Input, InputNumber, Popover, Tooltip, Typography } from 'antd';
import { useField, useFormikContext } from 'formik';
import { ProductsTableDataSource } from 'models/ProductsTableDataSource';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { SalesOrderModel } from 'models/SalesOrderModel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { DeliveryTypeCell } from './DeliveryTypeCell';
import { OcdCell } from './OcdCell/OcdCell';
import { SiteLineItemSelect } from './SiteLineItemSelect';
import { VendorCell } from './VendorCell';
import { WarehouseLineItemSelect } from './WarehouseLineItemSelect';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: ProductsTableDataSource;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, inputType, children, record, title, index, ...restProps }: EditableCellProps) => {
  const [errMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormikContext<SalesOrderModel>();

  const { isAltIdCellLoading } = useSelector((state: ReduxState) => state.app)

  /* ****************** Hook Api ****************** */









  const idx = values.lines.findIndex((line) => line.id === record?.id);
  const [x, a, { setValue: setExtPrice }] = useField(`lines.${idx}.extendedPrice`);
  const [n, m, { setValue: setTotalCost }] = useField(`lines.${idx}.totalCost`);
  const [{ value }, y, z] = useField<SalesOrderLineItemModel>(`lines.${idx}`);

  const handleQtyChange = (val: string | null): void => {
    setExtPrice(Number(val) * value.unitPrice);
    setTotalCost(Number(val) * value.unitCost);
  };
  const handlePriceChange = (val: string | null): void => {
    setExtPrice(Number(val) * value.quantity);
  };
  const handleUnitCostChange = (val: string | null): void => {
    setTotalCost(Number(val) * value.quantity);
  };


  const handleParser = (value: string | undefined): string => {
    if (!value) return '0'

    return value?.replace(/\$\s?|(,*)/g, '') ?? ''
  };
  const handleNewFormatter = (value: string | undefined): string => new Intl.NumberFormat('en-US').format(Number(value) ?? 0);

  // const handleTotalCost = (): void => {};

  const handleFocus = (ev: any): void => {
    ev.currentTarget.select();
  };

  const newInputNode = (): JSX.Element => {
    switch (dataIndex) {
      case 'description':
        return <Input />;

      case 'customerLineNumber':
        return <InputNumber style={{ width: '100%', margin: 0 }} controls={false} />;

      case 'vendorId':
        return <VendorCell id={record.id} vendorId={record.vendorId} index={idx} />;

      case 'siteId':
        return <SiteLineItemSelect siteId={record.siteId} id={record.id} index={idx} />;

      case 'warehouseId':
        return <WarehouseLineItemSelect id={record.id} siteId={record.siteId} warehouseId={record.warehouseId} index={idx} />;

      case 'productNumber':
        return <Input />;

      case 'alternateItemId':
        return <Input disabled={record?.isAlternateItemIdValidated} />


      case 'deliveryType':
        return <DeliveryTypeCell deliveryType={record.deliveryType} index={idx} />;

      case 'quantity':
        return <InputNumber onChange={handleQtyChange} style={{ width: '100%', margin: 0 }} controls={false} onFocus={handleFocus} parser={handleParser} step={'0'}  />;

      case 'ocd':
        return (
          <OcdCell index={idx} record={record} ownerId={record.ownerId} conditionId={record.conditionId} dispositionId={record.dispositionId} id={record.id} productNumber={record.productNumber} />
        );

      case 'unitPrice':
        return (
          <InputNumber
            formatter={handleNewFormatter}
            addonBefore={'$'}
            onChange={handlePriceChange}
            style={{ width: '100%', margin: 0 }}
            controls={false}
            onFocus={handleFocus}
            parser={handleParser}
            step={'0.00'}
          />
        );

      case 'unitCost':
        return (
          <InputNumber
            formatter={handleNewFormatter}
            addonBefore={'$'}
            onChange={handleUnitCostChange}
            style={{ width: '100%', margin: 0 }}
            controls={false}
            onFocus={handleFocus}
            parser={handleParser}
            step={'0.00'}
          />
        );

      default:
        return <></>;
    }
  };


  return (
    <td {...restProps}>
      {record?.isAlternateItemIdValidated && editing && dataIndex === 'alternateItemId' ?
        <Tooltip title={record?.isAlternateItemIdValidated ? 'There is already an alternate item id associated with this product' : undefined}>
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {newInputNode()}
          </Form.Item>
        </Tooltip>


        : editing ? (
          <Popover open={isOpen} content={<Typography.Text style={{ color: 'red' }}>{errMessage}</Typography.Text>}>
            <Form.Item name={dataIndex} style={{ margin: 0 }}>
              {newInputNode()}
            </Form.Item>
          </Popover>
        ) : (
          children
        )
      }
    </td >
  );
};
