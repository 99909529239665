import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import { ProductsTableDataSource } from 'models/ProductsTableDataSource';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { setIsChild } from 'redux/slices/selectedProductsSlice';
import { ProductSearchTab } from './ProductSearchTab';
import { ProductsSearchFilters } from './ProductsSearchFilters';
import { SublineProductAdhocCard } from './SublineProductAdhocCard';

interface Props {
  record: ProductsTableDataSource;
  isLineLevel?: boolean;
  showOptions?: string;
  index: number;
}

export interface SublineForm {
  quantity: number;
  listDiscountPercentage: string;
  listPrice: string;
  unitCost: string;
  itemId: string;
  productDescription: string;
}

export const AddChildModal: React.FC<Props> = ({ record, isLineLevel, showOptions, index }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const { products } = useSelector((state: ReduxState) => state.selectedProducts);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm<SublineForm>();

  const handleAdd = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    // dispatch(setIsChild(true));
    setIsOpen(true);
    event.stopPropagation();
  };

  const handleCloseModal = (): void => {
    // dispatch(setIsChild(false));
    setIsOpen(false);
  };

  const handleAdhoc = (): void => {
    modal.confirm({
      icon: undefined,
      bodyStyle: { paddingTop: 10 },
      title: (
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 25, padding: 10, marginBottom: 0 }}>
          Adhoc Submodule
        </Typography.Title>
      ),
      content: <SublineProductAdhocCard index={index} form={form} record={record} />,
      centered: true,
      okText: 'Submit',
      onOk: () => form.submit(),
      width: 600,
      closable: true,
      keyboard: true
    });
  };

  const titleJsx = (
    <Row align="middle" style={{ width: '100%', padding: '0 20px' }} justify="space-between">
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Typography.Title style={{ margin: 0, padding: 0, fontWeight: 150 }} level={4}>
            Add Submodules
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProductsSearchFilters />
        </Col>
        <Button style={{ marginLeft: 8 }} onClick={handleAdhoc} type="primary">
          Adhoc Product
        </Button>
      </Row>
    </Row>
  );

  return (
    <>
      {contextHolder}
      <Modal
        keyboard
        onCancel={handleCloseModal}
        closable
        title={titleJsx}
        // onOk={handleCloseModal}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1300}
        open={isOpen}>
        <ProductSearchTab index={index} isLineLevel={isLineLevel} record={record} />
      </Modal>
      <PlusOutlined style={{ opacity: showOptions === record.id ? 1 : 0 }} onClick={(event): void => handleAdd(event)} className="subline-add" />
    </>
  );
};
