/* eslint-disable react-hooks/exhaustive-deps */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Spin, Tooltip } from 'antd';
import { useField } from 'formik';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetReleasedProductQuery } from 'redux/services/useDonaldGrumpService';
interface Props {
  record: SalesOrderLineItemModel;
  index: number;
}

export const NewProductNumberCell: FC<Props> = ({ record, index }: Props) => {
  const [hasCheckedValidation, setHasCheckedValidation] = useState(record.isProductNumberValidated);

  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.salesOrders);
  const [x, _, { setValue }] = useField(`lines.${index}.isProductNumberValidated`);
  const [a, b, { setValue: setManufacturer }] = useField(`lines.${index}.manufacturer`);
  const [c, d, { setValue: setCategory }] = useField(`lines.${index}.category`);

  const {
    data: releasedProductData,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetReleasedProductQuery({ productNumber: record.productNumber, dataAreaId: selectedCompanyDataAreaId as string }, { skip: record.isProductNumberValidated || !selectedCompanyDataAreaId });

  const apiError = error as FetchBaseQueryError;

  // Handle validation by checking the flag in the api, if isProductNumberValidated is false
  useEffect(() => {
    if (!isError && !isLoading) {
      setValue(true);
    }
    if (!record.isProductNumberValidated && !hasCheckedValidation && !isLoading) {
      setHasCheckedValidation(true);
      setManufacturer(releasedProductData?.manufacturerId)
      setCategory(releasedProductData?.productGroupId)
    }
  }, [record.isProductNumberValidated, hasCheckedValidation, releasedProductData, isLoading, isError]);

  return (
    <Tooltip title={isError ? (apiError.data as string) : ''}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ color: isLoading ? 'black' : record.isProductNumberValidated ? 'green' : 'red' }}>{record.productNumber}</div>
      </Spin>
    </Tooltip>
  );
};
