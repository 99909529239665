import { Spin } from "antd"
import { useField, useFormikContext } from "formik"
import { ProductsTableDataSource } from "models/ProductsTableDataSource"
import { SalesOrderPayloadModel } from "models/SalesOrderPayloadModel"
import { FC, useEffect } from "react"
import { useGetDivisionsQuery } from "redux/services/useAbbyCadabbyService"
import { useGetProductXrefItemsQuery } from "redux/services/useFloBearApi"

interface Props {
    record: ProductsTableDataSource
    index: number
}

export const AlternateItemIdCell: FC<Props> = ({ record, index }) => {
    const [x, _, { setValue }] = useField(`lines.${index}.isAlternateItemIdValidated`);
    const [, , { setValue: setAltItemId }] = useField(`lines.${index}.alternateItemId`);

    const { values } = useFormikContext<SalesOrderPayloadModel>()



    const { data: divisionData, isLoading: isDivisionLoading, isFetching: isDivisionFetching } = useGetDivisionsQuery(values.customerId, { skip: !values.customerId || record.isAlternateItemIdValidated })


    const { data, isError, isLoading, isFetching } = useGetProductXrefItemsQuery({ customerId: divisionData?.data[0]?.id as string, altItemId: record.productNumber as string }, { skip: !divisionData?.data[0]?.id || !record.productNumber || record.isAlternateItemIdValidated })





    useEffect(() => {
        if (!isError && !isLoading && data?.data[0]) {
            setValue(true)
            setAltItemId(data?.data[0].alternateItemId)
        }
    }, [data?.data[0], isError, isLoading,])


    const isCellLoading = Boolean(isLoading || isFetching || isDivisionFetching || isDivisionLoading)

    // useEffect(() => {
    //     if (isCellLoading) dispatch(setIsAltIdCellLoading(true))
    //     else dispatch(setIsAltIdCellLoading(false))
    // })

    return (
        <Spin spinning={isCellLoading}>
            <div>{record.alternateItemId}</div>
        </Spin>
    )
}