import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Spin, Tooltip } from 'antd';
import { useField } from 'formik';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetReleasedProductQuery } from 'redux/services/useDonaldGrumpService';

interface Props {
  record: SalesOrderLineItemModel;
  index: number;
}

export const NewOcdCell: FC<Props> = ({ record, index }: Props) => {
  const isOcdValid = record.isOwnerValidated && record.isConditionValidated && record.isDispositionValidated;
  const [isValidChecked, setIsValidChecked] = useState(isOcdValid);
  const [isFound, setIsFound] = useState<boolean>();

  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.salesOrders);
  const [x, a, { setValue: setOwnerValidated }] = useField(`lines.${index}.isOwnerValidated`);
  const [y, b, { setValue: setConditionValidated }] = useField(`lines.${index}.isConditionValidated`);
  const [z, c, { setValue: setDispositionValidated }] = useField(`lines.${index}.isDispositionValidated`);


  const {
    data: releasedProductData,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetReleasedProductQuery({ productNumber: record.productNumber, dataAreaId: selectedCompanyDataAreaId as string }, { skip: !selectedCompanyDataAreaId });

  const optionsMemo = useMemo(() => {
    return releasedProductData?.releasedProductVariants?.map((variant) => ({
      label: `${variant.ownerId} :: ${variant.conditionId} :: ${variant.dispositionId}`.toUpperCase(),
      value: `${variant.ownerId} :: ${variant.conditionId} :: ${variant.dispositionId}`.toUpperCase()
    }));
  }, [releasedProductData?.releasedProductVariants]);

  const errorMessage = (): string => {
    if (isError && !isLoading) return (error as FetchBaseQueryError).data as string;
    if (!isFound) return 'No OCD Variant Exists';

    return '';
  };

  const releasedProductVariants = releasedProductData?.releasedProductVariants;
  const recordOcd = record.ownerId && record.conditionId && record.dispositionId ? `${record.ownerId} :: ${record.conditionId} :: ${record.dispositionId}`.toUpperCase() : undefined;

  useEffect(() => {
    setIsFound(releasedProductVariants && releasedProductVariants?.length > 0 && (!recordOcd || optionsMemo?.some((op) => op.value === recordOcd)));
    if (!isError && !isLoading && isFound) {
      setOwnerValidated(true);
      setConditionValidated(true);
      setDispositionValidated(true);
    }
    if (!isOcdValid && !isValidChecked && !isLoading) {
      setIsValidChecked(true);
    }
  }, [isError, isFound, isLoading, isOcdValid, isValidChecked, optionsMemo, recordOcd, releasedProductVariants]);

  return (
    <Tooltip title={!isFound ? errorMessage() : ''}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ color: isLoading || !(record.ownerId && record.conditionId && record.dispositionId) ? 'black' : !isOcdValid ? 'red' : 'green' }}>
          {record.ownerId && record.conditionId && record.dispositionId ? `${record.ownerId}::${record.conditionId}::${record.dispositionId}` : 'Edit line to select OCD'}
        </div>
      </Spin>
    </Tooltip>
  );
};
