import { Spin, Tooltip } from 'antd';
import { useField } from 'formik';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetVendorsQuery } from 'redux/services/useSimonSoundman';

interface Props {
  record: SalesOrderLineItemModel;
  idx: number;
}

export const NewVendorCell: FC<Props> = ({ record, idx }: Props) => {
  const [x, a, { setValue }] = useField(`lines.${idx}.isVendorValidated`);
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.salesOrders);

  const {
    data: vendorResponse,
    isLoading,
    isFetching,
    isError
  } = useGetVendorsQuery({ dataAreaId: selectedCompanyDataAreaId ?? '', offset: 0, vendorAccountContains: record.vendorId as string }, { skip: !record.vendorId });

  useEffect(() => {
    if (vendorResponse?.data.some((vendorId) => vendorId.vendorAccountNumber === record.vendorId)) {
      setValue(true);
    }
  }, [record.vendorId, vendorResponse?.data]);

  return (
    <Tooltip title={record.isVendorValidated ? '' : 'Vendor does not exist'}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ color: isLoading || isFetching ? 'black' : record.isVendorValidated ? 'green' : 'red' }}>{record.vendorId}</div>
      </Spin>
    </Tooltip>
  );
};
