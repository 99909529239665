import { Col, Collapse, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
const { Panel } = Collapse;

export const ReleaseNotesPage = (): JSX.Element => {
  const { version } = useParams();

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: 10, background: 'rgb(255, 234, 193)', borderRadius: 5 }}>
        <Col>
          <AntPageTitle text={'Release Notes'} />
        </Col>
      </Row>
      <Collapse defaultActiveKey={['4.6']}>
        <Panel header="Version 4.6" key="4.6">
          <div>Line Items</div>
          <ol>
            <li>The warehouse dropdown is no longer disabled after changing the site.</li>
            <li>The width of the sites dropdown box has been increased to show the full WarehouseId.</li>
            <li>Delivery type will now be set to Stock when a product quote is converted to a sales order.</li>
          </ol>

          <div>General</div>
          <ol>
            <li>Improvements have been made to required field validators.</li>
            <li>Inventory reservation setting now defaults to automatic.</li>
            <li>Delivery date has been removed from the cards on the landing page.</li>
            <li>QuoteId has been added to the cards on the landing page.</li>
            <li>QuoteId has been added as a filter to the landing page.</li>
          </ol>

          <div>Attachments</div>
          <ol>
            <li>HTM file extensions may now be added as attachments and are sent to D365 when posting the order.</li>
          </ol>

          <div>Submodules</div>
          <ol>
            <li>An edge case causing submodule editing not to work correctly has been resolved.</li>
          </ol>
        </Panel>
        <Panel header="Version 4.5.23027 Hotfix" key="4.5.23027">
          <div>Required Field Validation</div>
          <ol>
            <li>Posting a sales order after importing line items works again as intended.</li>
          </ol>
        </Panel>
        <Panel header="Version 4.5" key="4.5">
          <div>Delivery Information</div>
          <ol>
            <li>The form used to create new addresses has been improved.  Users may now use the address search and auto complete functionality to add addresses that are verified by Google.</li>
            <li>Users may override a verified address as necessary.</li>
            <li>Delivery address description in the delivery details tab is  no longer editable after the sales order has been saved. Note that this field has been deprecated and will be removed in an upcoming release.</li>
            <li>The address description is now set as the delivery description. Note that this field has been deprecated and will be removed in an upcoming release.</li>
            <li>The delivery name of the delivery details tab is now sent to D365 as the &quot;DeliveryAddressName&quot; of the sales order.</li>
          </ol>

          <div>General</div>
          <ol>
            <li>Users may now view release notes for the app by clicking their profile icon and clicking the release notes menu item.</li>
            <li>The required field validators no longer erroneously prevent a sales order from being posted.</li>
          </ol>

          <div>Overview Tab</div>
          <ol>
            <li>Currency formatting has been corrected on the line items summary.</li>
          </ol>

          <div>Header Info</div>
          <ol>
            <li>Reservation setting has been added to the header tab.  This value is sent to D365 sales order header, which is then used by D365 to set each line item.</li>
          </ol>

          <div>Products</div>
          <ol>
            <li>Delivery type now defaults to Stock again.</li>
            <li>The warehouse selection is no longer disabled on line items on edit.</li>
          </ol>
        </Panel>
        <Panel header="Version 4.4" key="4.4">
          <div>Attachments</div>
          <ol>
            <li>Attachments in sales orders now work the same way as attachments in deployment requests.</li>
            <li>Saving larger attachments no longer causes an error.</li>
          </ol>

          <div>Products</div>
          <ol>
            <li>Users may now create submodules for each product line item.</li>
            <li>Submodules in product quotes converted to sales orders are now persisted to the sales order.</li>
            <li>Minor formatting improvements have been made for product name and product description on the product tooltip.</li>
            <li>The table for line items has been rebuilt to work more like the table in product quotes</li>
          </ol>

          <div>General</div>
          <ol>
            <li>QuoteID is available again for sales orders that did not originate as a product quote.</li>
            <li>Many data structure have been made to fully support converting a product quote to a sales order.</li>
            <li>Minor visual enhancements have been made to the sales order post confirmation.</li>
            <li>Users may now view release notes for the app by clicking their profile icon and clicking the release notes menu item.</li>
          </ol>
        </Panel>
      </Collapse>
    </Fragment>
  );
};
