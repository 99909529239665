import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import { BulkValueInputs } from 'components/atoms/bulkValueInputs/BulkValueInputs';
import { ImportProducts } from 'components/atoms/ImportProducts';
import { ProductBulkInput } from 'components/atoms/ProductBulkInput';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderPayloadModel } from 'models/SalesOrderPayloadModel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setShowBulkApply } from 'redux/productsInfo/bulkApplySlice';
import { setSelectedLineItems } from 'redux/productsInfo/productsInfoSlice';
import { ReduxState } from 'redux/rootReducer';
import { ProductsSearchModal } from './ProductsSearchModal';

const styles: InlineStylesModel = {
  tabContainer: {
    paddingTop: 10
  },
  animation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemCount: {
    paddingLeft: '5px'
  }
};

export const ProductsNavigation: React.FC = () => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const { values, setFieldValue } = useFormikContext<SalesOrderPayloadModel>();
  const {
    productsInfo: { selectedLineItemIds },
    bulkApply: { showBulkApply }
  } = useSelector((state: ReduxState) => state);
  const [isPlay, _setIsPlay] = useState(false);
  const [isPause, _setIsPause] = useState(true);
  const { pathname } = useLocation();
  const isPosted = pathname.includes('/posted');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLocation = pathname.includes('search') ? 'search' : 'staged';

  const handleNavigation = (info: { key: string }): void => {
    if (info.key === 'search') {
      if (!pathname.includes('search')) {
        navigate(`${pathname}/search`);
      }
    } else {
      if (pathname.includes('search')) {
        navigate(pathname.replace('/search', ''));
      }
    }
  };

  const handleToggleShowBulkActions = (): void => {
    dispatch(setShowBulkApply(!showBulkApply));
  };

  /* ****************** Functions ****************** */

  const handleDeleteRows = (): void => {
    setFieldValue(
      'lines' as keyof SalesOrderPayloadModel,
      values.lines.filter((line) => !selectedLineItemIds.includes(line.id))
    );
    dispatch(setSelectedLineItems([]));
  };

  /* ****************** useEffects ****************** */
  useEffect(() => {
    if (!isPause) {
      setTimeout(() => {
        _setIsPause(true);
      }, 1500);
    }
    if (isPlay) _setIsPlay(false);
  }, [isPlay, isPause]);

  useEffect(() => {
    return (): void => {
      dispatch(setSelectedLineItems([]));
    };
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <ProductsSearchModal />
        <Space>
          <Tooltip title={!selectedLineItemIds.length ? 'Select product rows to delete.' : undefined}>
            <Button danger disabled={!selectedLineItemIds.length || isPosted} onClick={handleDeleteRows}>
              {!selectedLineItemIds.length ? 'Delete Rows' : `Delete ${selectedLineItemIds.length} Rows`}
            </Button>
          </Tooltip>
          <Tooltip title={values.lines.length < 2 ? 'Select multiple products to perform bulk actions.' : undefined}>
            <Button onClick={handleToggleShowBulkActions} disabled={values.lines.length < 2 || isPosted}>
              Bulk Actions
            </Button>
          </Tooltip>
          <ProductBulkInput />
          <Tooltip title="Download Template" mouseEnterDelay={1}>
            <Button icon={<DownloadOutlined />} href={`${assetsBaseUrl}/import-templates/MDSi_Sales_Order_Product_Import_Template.xlsx`} />
          </Tooltip>
          <ImportProducts />
        </Space>
      </div>
      <Row style={{ marginTop: showBulkApply ? 8 : 0 }}>
        <Col span={24}>
          <BulkValueInputs />
        </Col>
      </Row>
    </>
  );
};
