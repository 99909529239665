import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerNameModel } from 'models/CustomerNameModel';

interface InitialState {
  cachedCustomerList?: CustomerNameModel[];
  isLazyFetchLoading: boolean;
}
const initialState: InitialState = {
  cachedCustomerList: undefined,
  isLazyFetchLoading: false
};

export const customerCachingSlice = createSlice({
  name: 'cachedCustomerList',
  initialState,
  reducers: {
    setCachedCustomerList: (state, { payload }: PayloadAction<InitialState['cachedCustomerList']>) => {
      state.cachedCustomerList = payload;
    },
    setIsLazyFetchLoading: (state, { payload }: PayloadAction<InitialState['isLazyFetchLoading']>) => {
      state.isLazyFetchLoading = payload;
    }
  }
});

export const { setCachedCustomerList, setIsLazyFetchLoading } = customerCachingSlice.actions;
