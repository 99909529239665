import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLocalProduct } from 'hooks/useLocalProduct';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetReleasedProductQuery } from 'redux/services/useDonaldGrumpService';

interface Props {
  record: SalesOrderLineItemModel;
  index: number;
}

export const RefetchButton: React.FC<Props> = ({ record, index }: Props) => {
  const { clearLocalData } = useLocalProduct(record.productNumber);
  const { selectedCompanyDataAreaId: dataAreaId } = useSelector((state: ReduxState) => state.salesOrders);

  const { refetch } = useGetReleasedProductQuery({ dataAreaId: dataAreaId || '', productNumber: record.productNumber }, { skip: !dataAreaId });

  const handleRefetch = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    ev.stopPropagation();
    clearLocalData();
    refetch();
  };

  return <Button onClick={handleRefetch} className="product-table__refresh-icon" style={{ borderRadius: '50%' }} icon={<ReloadOutlined />} />;
};
