import { Spin, Tooltip } from 'antd';
import { useField } from 'formik';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetSitesQuery } from 'redux/services/useKingstonLivingstonService';

interface Props {
  record: SalesOrderLineItemModel;
  idx: number;
}

export const NewSiteCell: FC<Props> = ({ record, idx }: Props) => {
  const [x, a, { setValue }] = useField(`lines.${idx}.isSiteValidated`);

  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.salesOrders);

  const { data: sitesResponse, isFetching, isLoading } = useGetSitesQuery({ dataAreaId: selectedCompanyDataAreaId, offset: 0, overrideSkipTake: true
   });

  useEffect(() => {
    if (sitesResponse?.data.some((siteId) => siteId.siteId === record.siteId)) {
      setValue(true);
    }
  }, [record.siteId, sitesResponse?.data]);

  return (
    <Tooltip title={record.isSiteValidated ? '' : 'Site does not exist'}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ color: isLoading || isFetching ? 'black' : record.isSiteValidated ? 'green' : 'red' }}>{record.siteId}</div>
      </Spin>
    </Tooltip>
  );
};
