import React, { CSSProperties, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  style?: CSSProperties;
};

// eslint-disable-next-line react/prop-types
export const Stack: React.FC<Props> = ({ children, style }) => {
  return <div style={{ display: 'flex', gap: 5, ...style }}>{children}</div>;
};
