import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderModel } from 'models/SalesOrderModel';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { addSelectedSalesOrder, removeSelectedSalesOrder } from 'redux/salesOrder/selectedSalesOrdersSlice';

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  card: {
    borderRadius: 5
  },
  selectedCard: {
    // border: '2px solid #5867dd',
    borderRadius: 5
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '22px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: 'orange'
  },
  posted: {
    color: '#4E937A'
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  }
};

interface Props {
  salesOrder: SalesOrderModel;
}

const RecoverSalesOrderCard = ({ salesOrder }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedSalesOrders } = useSelector((state: ReduxState) => state.selectedSalesOrders);

  const isSelected = selectedSalesOrders.some((item) => item.id === salesOrder.id);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeSelectedSalesOrder(salesOrder));
    } else {
      dispatch(addSelectedSalesOrder(salesOrder));
    }
  };

  const readOnlyCardJSX = (
    <>
      <Row justify="space-between" align="middle" style={{ border: 'none', marginBottom: 5 }}>
        <Typography.Text style={styles.largeText}>
          <Tooltip title="Customer Name" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
            {salesOrder.customerName}
          </Tooltip>
        </Typography.Text>
        <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.customerId}>
            <Tooltip title="Customer Id" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.customerId}
            </Tooltip>
          </Typography>
        </Col>
        <Col>
          <Typography style={salesOrder.status === 'Draft' ? { ...styles.status, ...styles.draft } : salesOrder.status === 'Posted' ? { ...styles.status, ...styles.posted } : styles.status}>
            <Tooltip title="Status" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.status}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      {salesOrder.quoteId && <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Quote Id" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.quoteId}
            </Tooltip>
          </Typography>
        </Col>
      </Row>}
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Customer PO" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.customerPo}
            </Tooltip>
          </Typography>
        </Col>
        <Col>
          {salesOrder.status === 'Draft' ? (
            <Typography style={styles.soNumber}>
              <Tooltip title="Draft Id" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
                {salesOrder.draftId}
              </Tooltip>
            </Typography>
          ) : salesOrder.status === 'Posted' ? (
            <Typography style={styles.soNumber}>
              <Tooltip title="Sales Order Number" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
                {salesOrder.salesOrderNumber}
              </Tooltip>
            </Typography>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Divider />

      <Row justify="center">
        <Col style={{ textAlign: 'start', marginLeft: 10 }} span={8}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Sales Person" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.salesPersonFullName ? salesOrder.salesPersonFullName : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ fontSize: 80 }} />
        </Col>

        <Col style={{ textAlign: 'end', marginRight: 10 }} span={8}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Creation Date" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {new Date(salesOrder.createdDateTime).toLocaleDateString()}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Shipping Date" placement="bottom" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesOrder.requestedShipDate ? new Date(salesOrder.requestedShipDate).toLocaleDateString() : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
      </Row>
    </>
  );

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        // title={
        //   <Row justify="space-between" style={{ border: 'none' }}>
        //     <Typography.Text style={styles.largeText}>{salesOrder.customerName}</Typography.Text>
        //     <Button onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
        //   </Row>
        // }
        style={isSelected ? styles.selectedCard : styles.card}
        bodyStyle={styles.cardBody}
      // extra={<Button onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />}
      >
        {readOnlyCardJSX}
      </Card>
    </List.Item>
  );
};

export default RecoverSalesOrderCard;
