import { Typography } from 'antd';
import '../../common/styles/loaderStyle.less';

interface DirectionProps {
  direction: string;
  message?: string;
}

export const BlockLoader = ({ direction, message }: DirectionProps): JSX.Element => {
  return (
    <>
      <div className={direction} style={message ? { marginBottom: 0, height: 100, marginTop: 100 } : undefined}>
        <span className="loader-item">1</span>
        <span className="loader-item">2</span>
        <span className="loader-item">3</span>
        <span className="loader-item">4</span>
        <span className="loader-item">5</span>
        <span className="loader-item">6</span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Text style={{ fontSize: 20, marginTop: 0 }} id="importMessage">
          {message}
        </Typography.Text>
      </div>
    </>
  );
};
