/* eslint-disable react/react-in-jsx-scope */
import { Form, Input, InputProps } from 'antd';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  label?: string;
}

export const TextInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Input
        {...rest}
        value={value || undefined}
        onChange={(e): void => setValue(e.target.value)}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
