import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { SalesOrderModel } from 'models/SalesOrderModel';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { setSelectedSalesOrders } from 'redux/salesOrder/selectedSalesOrdersSlice';
import { usePurgeSalesOrderMutation, useRecoverSalesOrderMutation } from 'redux/services/useMamaBearService';
import { BlockLoader } from './BlockLoader';

interface Props {
  responseDeletedSalesOrders: SalesOrderModel[] | undefined;
}

const RecoverActions = ({ responseDeletedSalesOrders }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [purgeCount, _setPurgeCount] = useState(1);
  const { selectedSalesOrders } = useSelector((state: ReduxState) => state.selectedSalesOrders);

  const [recoverSalesOrder, { isLoading: recoveringSalesOrder }] = useRecoverSalesOrderMutation();
  const [purgeSalesOrder, { isLoading: isPurgingSalesOrder }] = usePurgeSalesOrderMutation();

  const totalSelectedSalesOrders = selectedSalesOrders.length;

  const handleRestoreSelectedRoles = async (): Promise<void> => {
    if (selectedSalesOrders.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const salesOrder of selectedSalesOrders) {
      try {
        await recoverSalesOrder({ productSalesOrderId: salesOrder.id }).unwrap();
      } catch {
        message.error(`Sales Order item ${salesOrder.id} failed to be recovered!`);
      }
    }

    dispatch(setSelectedSalesOrders([]));
    message.success(`Sales order item(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (selectedSalesOrders.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const salesOrder of selectedSalesOrders) {
      try {
        await purgeSalesOrder({ productSalesOrderId: salesOrder.id }).unwrap();
        _setPurgeCount((prev) => prev + 1);
      } catch (error) {
        message.error(`Failed to delete Sales Order item ${salesOrder.id}`);
      }
    }

    // _setPurgeCount(1);
    message.success(`Purged sales order item(s) successfully!`);
    _setShowPurgeModal(false);
    _setPurgeCount(1);
    dispatch(setSelectedSalesOrders([]));
  };

  const handleSelectAll = (): void => {
    if (responseDeletedSalesOrders !== undefined) {
      dispatch(setSelectedSalesOrders(responseDeletedSalesOrders));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedSalesOrders([]));
  };

  return (
    <Row style={{ paddingTop: 10 }} justify="space-between">
      <Row justify="space-between">
        <Button onClick={handleSelectAll} style={{ marginRight: 5 }} type="primary">
          Select All
        </Button>
        <Button onClick={handleClearAll} ghost danger disabled={!totalSelectedSalesOrders}>
          Clear All
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreSelectedRoles}
          style={totalSelectedSalesOrders ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={recoveringSalesOrder}
          disabled={!totalSelectedSalesOrders}>
          Restore Selected ({totalSelectedSalesOrders})
        </Button>
        <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isPurgingSalesOrder} disabled={!totalSelectedSalesOrders}>
          Purge Selected ({totalSelectedSalesOrders})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedSalesOrders})`}
        open={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isPurgingSalesOrder}
        maskClosable={!isPurgingSalesOrder}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isPurgingSalesOrder ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isPurgingSalesOrder ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isPurgingSalesOrder}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging Catalog Item ${purgeCount} of ${totalSelectedSalesOrders}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED SALES ORDERS.</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
