import { Spin, Tooltip } from 'antd';
import { useField } from 'formik';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/rootReducer';
import { useGetWarehousesQuery } from 'redux/services/useKingstonLivingstonService';

interface Props {
  record: SalesOrderLineItemModel;
  idx: number;
}

export const NewWarehouseCell: FC<Props> = ({ record, idx }: Props) => {
  const [x, a, { setValue }] = useField(`lines.${idx}.isWarehouseValidated`);
  const [lineValue] = useField<SalesOrderLineItemModel>(`lines.${idx}`);
  const { selectedCompanyDataAreaId: dataAreaId } = useSelector((state: ReduxState) => state.salesOrders);

  const {
    data: warehouseResponse,
    isLoading,
    isFetching,
    isError
  } = useGetWarehousesQuery(
    {
      site: lineValue.value.siteId ?? '',
      offset: 0,
      dataAreaId,
      overrideSkipTake: true
    },
    { skip: !lineValue.value.siteId || !dataAreaId }
  );

  useEffect(() => {
    if (warehouseResponse && !isError) {
      if (warehouseResponse.data.some((warehouse) => warehouse.warehouseId === record.warehouseId)) {
        setValue(true);
      }
    }
  }, [record.warehouseId, warehouseResponse]);

  return (
    <Tooltip title={!warehouseResponse?.totalCount ? 'Site not valied' : record.isWarehouseValidated ? '' : 'Warehouse not valid'}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ color: record.isWarehouseValidated ? 'green' : 'red' }}>{record.warehouseId}</div>
      </Spin>
    </Tooltip>
  );
};
