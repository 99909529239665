import { Row, Typography } from "antd";
import Space from "antd/lib/space";
import { FC } from "react";
import Lottie from 'react-lottie-player';
import searchLottie from '../../common/assets/search.json';

interface Props {
    message: string
}

export const WaitToSearch: FC<Props> = ({message}) => {
    return (
        <Row justify="center">
        <Space direction="vertical">
          <Lottie style={{ width: 400, height: 400 }} animationData={searchLottie} loop play />
          <Row justify="center">
            <Typography.Text style={{ fontSize: 20 }}>{message}</Typography.Text>
          </Row>
        </Space>
      </Row>
    )
}