import { CheckOutlined, DeleteOutlined, DownOutlined, EditOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Popover, Row, Space, Table, Tooltip, message } from 'antd';
import { MessageType } from 'antd/lib/message';
import { ColumnType } from 'antd/lib/table';
import { moneyFormatter } from 'common/helpers/moneyFormatter';
import { AlternateItemIdCell } from 'components/atoms/AlternateItemIdCell';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { EditableCell } from 'components/atoms/EditableCell';
import { RefetchButton } from 'components/atoms/RefetchButton';
import { NewOcdCell } from 'components/atoms/newCells/NewOcdCell';
import { NewProductNumberCell } from 'components/atoms/newCells/NewProductNumberCell';
import { NewSiteCell } from 'components/atoms/newCells/NewSiteCell';
import { NewVendorCell } from 'components/atoms/newCells/NewVendorCell';
import { NewWarehouseCell } from 'components/atoms/newCells/NewWarehouseCell';
import { AddChildModal } from 'components/molecules/AddChildModal';
import { useField, useFormikContext } from 'formik';
import { ProductsTableDataSource } from 'models/ProductsTableDataSource';
import { SalesOrderLineItemModel } from 'models/SalesOrderLineItemModel';
import { SalesOrderPayloadModel } from 'models/SalesOrderPayloadModel';
import { FixedType, RenderExpandIconProps } from 'rc-table/lib/interface';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setSelectedLineItems } from 'redux/productsInfo/productsInfoSlice';
import { ReduxState } from 'redux/rootReducer';
import { useGetSalesOrderQuery } from 'redux/services/useMamaBearService';
import { SublineTable } from './SublineTable';

interface EditableColumnType extends ColumnType<ProductsTableDataSource> {
  editable?: boolean;
  fixed?: FixedType;
}

interface RowPayload {
  customerLineNumber: number;
  condition: string;
  quantity: number;
  unitPrice: number;
  unitCost: number;
  productNumber: string;
  description: string;
  vendorId: string;
  siteId: string;
  warehouseId: string;
  ocd: string;
  deliveryType: string;
}

export const ProductsTable: React.FC = () => {
  const [idx, setIdx] = useState<number | undefined>(undefined);
  const [z, c, { setValue }] = useField(`lines.${idx}`);
  const dispatch = useDispatch();


  const [form] = Form.useForm();
  const {
    values: { lines },
    setFieldValue,
    setValues
  } = useFormikContext<SalesOrderPayloadModel>();

  console.log(lines, 'lines')


  const { id: salesOrderId } = useParams();
  /* ****************** Hook Api ****************** */
  const { data: salesOrderData, isLoading: isOrderLoading, isFetching } = useGetSalesOrderQuery({ id: salesOrderId as string }, { skip: !salesOrderId });




  const [showOptions, setShowOptions] = useState<string>('');
  const [editingKey, setEditingKey] = useState('');
  const { isTableLoading } = useSelector((state: ReduxState) => state.productsInfo);
  const { isAltIdCellLoading } = useSelector((state: ReduxState) => state.app);
  const { importLoadingMessage } = useSelector((state: ReduxState) => state.salesOrders);
  const isEditing = (record: ProductsTableDataSource): boolean => record.id === editingKey;
  const [isAltEditable, setIsAltEditable] = useState(false)


  const save = (): void => {
    try {
      const row = form.getFieldsValue() as RowPayload;

      if (row.unitPrice === undefined || row.unitCost === undefined || row.quantity === undefined) {
        message.error('Unable to update product line item. Try again.');

        return;
      }

      setValue({ ...z.value, ...{ ...row, ownerId: row.ocd.split(' :: ')[0], conditionId: row.ocd.split(' :: ')[1], dispositionId: row.ocd.split(' :: ')[2] } });

      setEditingKey('');
      setIsAltEditable(false)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: Partial<SalesOrderLineItemModel>, idx: number): void | MessageType => {
    if (!record.id) return message.error('Error occured')
    setIdx(idx);
    setEditingKey(record.id);

    form.setFieldsValue({
      customerLineNumber: record.customerLineNumber,
      alternateItemId: record.alternateItemId,
      condition: record.conditionId,
      quantity: record.quantity,
      unitPrice: record.unitPrice,
      unitCost: record.unitCost,
      productNumber: record.productNumber,
      description: record.description,
      vendorId: record.vendorId,
      siteId: record.siteId,
      warehouseId: record.warehouseId,
      ocd: `${record.ownerId} :: ${record.conditionId} :: ${record.dispositionId}`,
      deliveryType: record.deliveryType,
      extendedPrice: record.extendedPrice
    });


  };

  const handleDelete = (record: ProductsTableDataSource): void => {
    setFieldValue(
      'lines',
      lines.filter((line) => line.id !== record.id).map((line, idx) => ({ ...line, deliveryType: 'stock', lineNumber: idx + 1, subLines: line.subLines ? line.subLines.map((subLine, childidx) => ({...subLine, lineNumber: (idx + 1)  + ((childidx + 1)* 0.01) })) : []  }))
    );
    setEditingKey('');
  };

  const columns: EditableColumnType[] = [
    {
      title: '',
      width: 20,
      render: (_: string, record, idx): JSX.Element => <Row justify="center">{<AddChildModal index={idx} record={record} isLineLevel={true} showOptions={showOptions} />}</Row>
    },
    {
      title: 'Line #',
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      width: 60,
      render: (_, record, idx): JSX.Element => <div>{idx + 1}</div>
    },
    {
      title: 'Cust #',
      dataIndex: 'customerLineNumber',
      key: '',
      width: 70,
      editable: true
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      width: 155,
      render: (_: string, record, index): JSX.Element => (
        <Space>
          <Popover
            content={
              <Space direction="vertical">
                <div>Manufacturer: {record.manufacturer !== '' ? record.manufacturer : 'N/A'}</div>
                <div>Category: {record.category !== '' ? record.category : 'N/A'}</div>
              </Space>
            }>
            <InfoCircleOutlined style={{ color: '#2786fa' }} />
          </Popover>
          <NewProductNumberCell index={index} record={record} />
        </Space>
      )
    },
    {
      title: 'Alt Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId',
      ellipsis: true,
      editable: true,
      width: 125,
      render: (value, record, idx) => <AlternateItemIdCell index={idx} record={record} />

    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      editable: true,
      width: 200,
      render: (_: string, record): JSX.Element => <Tooltip title={record.description}>{record.description}</Tooltip>
    },
    { title: 'Owner::Condition::Disposition', editable: true, dataIndex: 'ocd', key: 'ocd', width: 250, render: (_: string, record, idx): JSX.Element => <NewOcdCell index={idx} record={record} /> },
    { title: 'Site', editable: true, dataIndex: 'siteId', key: 'siteId', width: 110, render: (_: string, record, idx): JSX.Element => <NewSiteCell idx={idx} record={record} /> },

    { title: 'Warehouse', editable: true, dataIndex: 'warehouseId', key: 'warehouseId', width: 110, render: (_: string, record, idx): JSX.Element => <NewWarehouseCell idx={idx} record={record} /> },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      editable: true,
      width: 75
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record): JSX.Element => <>{moneyFormatter.format(record.unitPrice)}</>
    },
    {
      title: 'Ext Price',
      dataIndex: 'extendedPrice',
      key: 'extendedPrice',
      width: 125,
      align: 'right',
      render: (_: string, record): JSX.Element => <>{moneyFormatter.format(Number(record.extendedPrice))}</>
    },
    {
      title: 'Delivery Type',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      width: 200,
      editable: true,
      render: (_: string, record): JSX.Element => <div>{record.deliveryType}</div>
    },
    {
      title: 'Vendor',
      dataIndex: 'vendorId',
      key: 'vendorId',
      editable: true,
      width: 100,
      render: (_: string, record, idx: number): JSX.Element => {
        return <NewVendorCell idx={idx} record={record} />;
      }
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      key: 'unitCost',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record): JSX.Element => <>{moneyFormatter.format(Number(record.unitCost))}</>
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      width: 175,
      align: 'right',
      render: (_: string, record): JSX.Element => <>{moneyFormatter.format(Number(record.quantity * record.unitCost))}</>
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      key: 'margin',
      width: 175,
      align: 'right',
      render: (_: string, record): JSX.Element => (
        <div style={{ color: record.extendedPrice - record.totalCost < 0 ? 'red' : 'black' }}>
          {record.totalCost !== 0 || record.totalCost !== null
            ? moneyFormatter.format(record.extendedPrice - record.totalCost)
            : moneyFormatter.format(record.extendedPrice - Number(record.quantity * record.unitCost))}
        </div>
      )
    },
    {
      title: 'Action',
      width: 115,
      align: 'center',
      key: 'action',
      fixed: 'right',
      render: (_, record, idx: number): JSX.Element => {
        return (
          <Row style={{ width: '100%' }} justify="center" gutter={[5, 5]}>
            <Col>
              {editingKey === record.id ? (
                <Button style={{ borderRadius: '50%' }} onClick={(): void => save()} icon={<CheckOutlined />} />
              ) : (
                <Button style={{ borderRadius: '50%' }} disabled={!!editingKey} onClick={(): void | MessageType => edit(record, idx ?? 0)} icon={<EditOutlined />} />
              )}
            </Col>
            <Col>
              <Button
                className="product-table__delete-icon"
                onClick={(e): void => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: `Delete ${record.productNumber}`,
                    onOk: () => handleDelete(record),
                    okText: 'Delete',
                    okType: 'danger',
                    content: 'Are you sure you want to delete this product?'
                  });
                }}
                style={{ borderRadius: '50%' }}
                icon={<DeleteOutlined />}
              />
            </Col>
            <Col>
              <RefetchButton record={record} index={idx} />
            </Col>
          </Row>
        );
      }
    }
  ];
  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: SalesOrderLineItemModel[]): void => {
      setEditingKey('');
      dispatch(setSelectedLineItems(selectedRows.map((row) => row.id)));
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ProductsTableDataSource, idx: number | undefined): any => ({
        record,
        inputType: col.title === 'Condition' ? 'string' : 'number',
        dataIndex: col.dataIndex,
        title: `${col.title}`,
        editing: isEditing(record)
        // onChange: save(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        style={{ marginTop: 5 }}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        dataSource={lines as ProductsTableDataSource[]}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 3000 }}
        loading={{ spinning: isTableLoading, indicator: <BlockLoader direction="loader loader--slideUp" message={importLoadingMessage ?? ''} /> }}
        rowSelection={{
          ...rowSelection
        }}
        rowKey={(row): string => row.id}
        expandable={{
          columnWidth: 30,
          expandIcon: ({ onExpand, expanded, record }: RenderExpandIconProps<ProductsTableDataSource>): JSX.Element | undefined => {
            if (!record.subLines) return undefined;
            if (record.subLines.length === 0) return undefined;

            return expanded ? (
              <DownOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ) : (
              <RightOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            );
          },
          expandedRowRender: (record, index): React.ReactNode => {
            return <SublineTable index={index} product={record} subLines={record.subLines ?? []} />;
          }
        }}
        onRow={(record: SalesOrderLineItemModel, idx: number | undefined) => ({
          onClick: (): void => {
            if (editingKey !== record.id) {
              if (editingKey) save();
              edit(record, idx ?? 0);
            }
          },
          onMouseLeave: (): void => {
            setShowOptions('');
          },
          onMouseEnter: (): void => {
            setShowOptions(record.id);
          },
          onKeyDown: (event): void => {
            if (event.key.toLocaleLowerCase() === 'enter') {
              if (editingKey === record.id) save();
            }
            if (event.key.toLocaleLowerCase() === 'escape') {
              // handleCancel();
            }
          }
        })}
      />
    </Form>
  );
};
