/* eslint-disable react/react-in-jsx-scope */
import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOcdValues } from 'redux/productsInfo/productsInfoSlice';
import { ReduxState } from 'redux/rootReducer';
import { useCreateReleasedProductVariantMutation } from 'redux/services/useDonaldGrumpService';
import OcdConditionSelect from './OcdConditionSelect';
import OcdDispositionSelect from './OcdDispositionSelect';
import OcdOwnerSelect from './OcdOwnerSelect';

const styles: InlineStylesModel = {
  ocdButton: {
    width: '100%'
  }
};

interface Props {
  productNumber: string;
  index: number;
  isError: boolean;
}

const AddOcdModal = ({ productNumber, index, isError }: Props): JSX.Element => {
  const [showOcdInputs, _setShowOcdInput] = useState(false);
  const onClose = (): void => _setShowOcdInput(false);
  const onOpen = (): void => _setShowOcdInput(true);
  const dispatch = useDispatch();

  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.salesOrders);
  const [createVariant, { isLoading, error, reset, data }] = useCreateReleasedProductVariantMutation();
  const [form] = Form.useForm<{ ownerId: string; conditionId: string; dispositionId: string }>();

  const handleClose = (): void => {
    onClose();
    form.resetFields();
    reset();
  };

  const handleFormSubmit = async (values: { ownerId: string; conditionId: string; dispositionId: string }): Promise<void> => {
    try {
      const data = await createVariant({ dataAreaId: selectedCompanyDataAreaId || '', productNumber, ocdData: values, createForProductMasterIfNotExist: true }).unwrap();

      dispatch(setOcdValues(`${data.ownerId} :: ${data.conditionId} :: ${data.dispositionId}`));

      handleClose();
    } catch (error) {
      //
    }
  };

  const ocd = `${data?.ownerId} :: ${data?.conditionId} :: ${data?.dispositionId}`;

  return (
    <>
      <Button icon={<PlusOutlined />} style={styles.ocdButton} onClick={onOpen} disabled={isError}>
        Add OCD
      </Button>
      <Modal
        title="Create OCD"
        open={showOcdInputs}
        onOk={(): void => {
          sessionStorage.removeItem(productNumber);
          form.submit();
        }}
        onCancel={handleClose}
        confirmLoading={isLoading}
        okText="Create Variant"
        okButtonProps={{ icon: <PlusOutlined /> }}>
        {data && <Alert style={{ marginBottom: 10 }} message={`New Variant Tip: ${ocd}`} />}
        {error && <Alert style={{ marginBottom: 20 }} message={(error as any).data ?? 'Server error'} type="error" />}
        <Form form={form} initialValues={{ owner: '', condition: '', disposition: '' }} onFinish={handleFormSubmit}>
          <Form.Item name="ownerId" rules={[{ required: true, message: 'Please Select Owner!' }]}>
            <OcdOwnerSelect
              onChange={(value): void => {
                form.setFields([{ name: 'ownerId', value }]);
              }}
            />
          </Form.Item>
          <Form.Item name="conditionId" rules={[{ required: true, message: 'Please Select Condition!' }]}>
            <OcdConditionSelect
              onChange={(value): void => {
                form.setFields([{ name: 'conditionId', value }]);
              }}
            />
          </Form.Item>
          <Form.Item name="dispositionId" rules={[{ required: true, message: 'Please Select Disposition!' }]}>
            <OcdDispositionSelect
              onChange={(value): void => {
                form.setFields([{ name: 'dispositionId', value }]);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddOcdModal;
