import { BarsOutlined, CarOutlined, CheckOutlined, FileSearchOutlined, HddOutlined, ReconciliationOutlined, RightOutlined, UserAddOutlined } from '@ant-design/icons';
import { Menu, Space } from 'antd';
import { SalesOrderFormMenuKeys } from 'common/enums/MenuKeys';
import { colors, toRgb } from 'common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderPayloadModel } from 'models/SalesOrderPayloadModel';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setFilterCustomerId, setFilterCustomerName } from 'redux/salesOrder/customerSelectionFiltersSlice';
import { SalesOrderFormHeaderInfo } from './SalesOrderFormHeaderInfo';

const styles: InlineStylesModel = {
  valid: {
    color: toRgb(colors.illuminatingEmerald)
  }
};

export const SalesOrderFormNavigation: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { id } = useParams();
  const { values } = useFormikContext<SalesOrderPayloadModel>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ******************** Variables / Functions ******************** */
  const validProductsStep =
    !!values.lines.length &&
    !values.lines.some(
      (lineItem) =>
        !lineItem.ownerId || !lineItem.conditionId || !lineItem.dispositionId || !lineItem.siteId || !lineItem.warehouseId || !lineItem.quantity || !lineItem.deliveryType || !lineItem.productNumber || !lineItem.isOwnerValidated || !lineItem.isConditionValidated || !lineItem.isDispositionValidated
    );

  const validCustomerStep = Boolean(values.customerId);
  const validHeaderInfoStep = Boolean(
    values.customerPo &&
    values.salesPersonFullName &&
    values.salesSupportFullName &&
    values.buyerId &&
    values.buyerName &&
    values.salesOrderType &&
    values.siteId &&
    values.warehouseId &&
    values.inventoryReservationMethod

  );
  const validDeliveryAddressStep = Boolean(values.deliveryAddressLocationId);
  const validDeliveryDetailsStep = Boolean(values.requestedShipDate && values.deliveryContactName && values.deliveryContactPhone && values.freightTerms && values.modeOfDelivery && values.deliveryAddressName);
  const areAllStepsCompleted = validCustomerStep && validHeaderInfoStep && validDeliveryAddressStep && validDeliveryDetailsStep && validProductsStep;

  const isDraft = pathname.includes('/drafts/');
  const isOrder = pathname.includes('/orders/');
  const isCreate = pathname.includes('/create');
  const isPosted = pathname.includes('/posted');
  const isAttachments = pathname.includes('/attachments');

  const currentLocation = useMemo(() => {
    if (pathname.includes(SalesOrderFormMenuKeys.HeaderInfo)) {
      return SalesOrderFormMenuKeys.HeaderInfo;
    } else if (pathname.includes(SalesOrderFormMenuKeys.DeliveryAddress)) {
      return SalesOrderFormMenuKeys.DeliveryAddress;
    } else if (pathname.includes(SalesOrderFormMenuKeys.DeliveryDetails)) {
      return SalesOrderFormMenuKeys.DeliveryDetails;
    } else if (pathname.includes(SalesOrderFormMenuKeys.ProductsSelection)) {
      return SalesOrderFormMenuKeys.ProductsSelection;
    } else if (pathname.includes(SalesOrderFormMenuKeys.Overview)) {
      return SalesOrderFormMenuKeys.Overview;
    } else if (pathname.includes(SalesOrderFormMenuKeys.Attachments)) {
      return SalesOrderFormMenuKeys.Attachments;
    } else {
      return SalesOrderFormMenuKeys.CustomerSelection;
    }
  }, [pathname]);

  const handleNavigation = (info: { key: string }): void => {
    if (isCreate) {
      //reset Filters back to start
      dispatch(setFilterCustomerId(''));
      dispatch(setFilterCustomerName(''));
      if (info.key === SalesOrderFormMenuKeys.CustomerSelection) {
        navigate('/create');
      } else if (info.key === SalesOrderFormMenuKeys.ProductsSelection) {
        navigate(`/create/${info.key}`);
      } else {
        navigate(`/create/${info.key}`);
      }
    } else if (isDraft) {
      if (info.key === SalesOrderFormMenuKeys.CustomerSelection) {
        navigate(`/drafts/${id}`);
      } else {
        navigate(`/drafts/${id}/${info.key}`);
      }
    } else if (isPosted) {
      if (info.key === SalesOrderFormMenuKeys.CustomerSelection) {
        navigate(`/posted/${id}`);
      } else {
        navigate(`/posted/${id}/${info.key}`);
      }
    } else if (isOrder) {
      if (info.key === SalesOrderFormMenuKeys.CustomerSelection) {
        navigate(`/orders/${id}`);
      } else {
        navigate(`/orders/${id}/${info.key}`);
      }
    } else if (isAttachments) {
      if (info.key === SalesOrderFormMenuKeys.Attachments) {
        navigate(`/attachments/${id}`);
      } else {
        navigate(`/attachments/${id}/${info.key}`);
      }
    }
  };

  const stepsItems = [
    {
      key: SalesOrderFormMenuKeys.CustomerSelection,
      label: (
        <Space style={validCustomerStep ? styles.valid : undefined}>
          <UserAddOutlined />
          <div>Customer</div>
          {validCustomerStep && <CheckOutlined />}
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.HeaderInfo,
      label: (
        <Space style={validHeaderInfoStep ? styles.valid : undefined}>
          <BarsOutlined />
          <div>Header Info</div>
          {validHeaderInfoStep && <CheckOutlined />}
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.DeliveryAddress,
      label: (
        <Space style={validDeliveryAddressStep ? styles.valid : undefined}>
          <CarOutlined />
          <div>Delivery Address</div>
          {validDeliveryAddressStep && <CheckOutlined />}
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.DeliveryDetails,
      label: (
        <Space style={validDeliveryDetailsStep ? styles.valid : undefined}>
          <ReconciliationOutlined />
          <div>Delivery Details</div>
          {validDeliveryDetailsStep && <CheckOutlined />}
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.ProductsSelection,
      label: (
        <Space style={validProductsStep ? styles.valid : undefined}>
          <HddOutlined />
          <div>Products</div>
          {validProductsStep && <CheckOutlined />}
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.Attachments,
      label: (
        <Space>
          <HddOutlined />
          <div>Attachments</div>
          <RightOutlined />
        </Space>
      )
    },
    {
      key: SalesOrderFormMenuKeys.Overview,
      label: (
        <Space>
          <FileSearchOutlined />
          <div>Overview</div>
        </Space>
      )
    }
  ];

  /* ******************** Renderer ******************** */
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SalesOrderFormHeaderInfo isFormValid={areAllStepsCompleted} />
      <Menu items={stepsItems} onClick={handleNavigation} mode="horizontal" style={{ justifyContent: 'center' }} selectedKeys={[currentLocation]}>
        {/* <Menu.Item key={SalesOrderFormMenuKeys.CustomerSelection}>
          <Space style={validCustomerStep ? styles.valid : undefined}>
            <UserAddOutlined />
            <div>Customer</div>
            {validCustomerStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.HeaderInfo}>
          <Space style={validHeaderInfoStep ? styles.valid : undefined}>
            <BarsOutlined />
            <div>Header Info</div>
            {validHeaderInfoStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.DeliveryAddress}>
          <Space style={validDeliveryAddressStep ? styles.valid : undefined}>
            <CarOutlined />
            <div>Delivery Address</div>
            {validDeliveryAddressStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.DeliveryDetails}>
          <Space style={validDeliveryDetailsStep ? styles.valid : undefined}>
            <ReconciliationOutlined />
            <div>Delivery Details</div>
            {validDeliveryDetailsStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.ProductsSelection}>
          <Space style={validProductsStep ? styles.valid : undefined}>
            <HddOutlined />
            <div>Products</div>
            {validProductsStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.Attachments}>
          <Space>
            <HddOutlined />
            <div>Attachments</div>
            <RightOutlined />
          </Space>
        </Menu.Item> */}
        {/* <Menu.Item key={SalesOrderFormMenuKeys.Overview}>
          <Space>
            <FileSearchOutlined />
            <div>Overview</div>
          </Space>
        </Menu.Item> */}
      </Menu>
    </Space>
  );
};
