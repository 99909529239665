import { BackTop, Layout } from 'antd';
import { SalesOrderFormMenuKeys } from 'common/enums/MenuKeys';
import { ErrorFallbackJSX } from 'components/atoms/ErrorFallback';
import { Loader } from 'components/atoms/Loader';
import { AttachmentsStep } from 'components/organisms/AttachmentsStep';
import { CustomerSelectionStep } from 'components/organisms/CustomerSelectionStep';
import { DeliveryAddressStep } from 'components/organisms/DeliveryAddressStep';
import { DeliveryDetailsStep } from 'components/organisms/DeliveryDetailsStep';
import { HeaderInfoStep } from 'components/organisms/HeaderInfoStep';
import { OverviewStep } from 'components/organisms/OverviewStep';
import { PageHeader } from 'components/organisms/PageHeader';
import { PageSubHeader } from 'components/organisms/PageSubHeader';
import { ProductsSelectionStep } from 'components/organisms/ProductsSelectionStep';
import { ProductsTable } from 'components/organisms/ProductsTable';
import { useAuthentication } from 'hooks/useAuthentication';
import Cookies from 'js-cookie';
import { AcuityContextModel } from 'models/AcuityContextModel';
import { About } from 'pages/about/About';
import { EditProfilePage } from 'pages/editProfile/EditProfile';
import { Forbidden } from 'pages/forbidden/Forbidden';
import { Home } from 'pages/home/Home';
import { PostedSalesOrder } from 'pages/postedSalesOrder/PostedSalesOrder';
import { Recover } from 'pages/recover/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { SalesOrderForm } from 'pages/salesOrderForm/salesOrderForm';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { InlineStylesModel } from '../models/InlineStylesModel';
import { setAcuityContext } from '../redux/app/app-slice';

const { Header, Content } = Layout;

const styles: InlineStylesModel = {
  layout: {
    minHeight: '100vh'
  },
  header: {},
  content: {
    padding: '10px 50px',
    width: '100%',
    maxWidth: 1920,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  somethingWrong: {
    textAlign: 'center',
    height: '100vh'
  }
};

function App(): JSX.Element {
  // const { accessToken } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();

  const { accessToken, isLoading } = useAuthentication();

  // Fetch cookie and set in store
  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
    const cookieJSON: AcuityContextModel = cookie ? JSON.parse(cookie) : undefined;

    if (cookieJSON?.selectedCustomer) {
      dispatch(setAcuityContext(cookieJSON));
    }
  }, [dispatch]);

  if (isLoading || accessToken === null) {
    return <Loader />;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallbackJSX}
      onReset={(): void => {
        // reset the state of your app so the error doesn't happen again
        console.log('reset');
      }}>
      <Layout style={styles.layout}>
        <BackTop style={{ zIndex: 10000 }} />
        <Header style={{ padding: 0 }}>
          <PageHeader />
        </Header>
        {location.pathname !== '/about' ? <PageSubHeader /> : null}

        <Content style={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recover" element={<Recover />} />
            <Route path="/about" element={<About />} />
            <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
            <Route path="/profile/" element={<EditProfilePage />} />
            <Route path="/unauthorized" element={<Forbidden />} />
            <Route path="/drafts/:id" element={<SalesOrderForm />}>
              <Route index element={<CustomerSelectionStep />} />
              <Route path={SalesOrderFormMenuKeys.HeaderInfo} element={<HeaderInfoStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryAddress} element={<DeliveryAddressStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryDetails} element={<DeliveryDetailsStep />} />
              <Route path={SalesOrderFormMenuKeys.Attachments} element={<AttachmentsStep />} />
              <Route path={SalesOrderFormMenuKeys.ProductsSelection} element={<ProductsSelectionStep />}>
                <Route index element={<ProductsTable />} />
                {/* <Route path="search" element={<ProductSearchTab />} /> */}
              </Route>
              <Route path={SalesOrderFormMenuKeys.Overview} element={<OverviewStep />} />
            </Route>
            <Route path="/orders/:id" element={<SalesOrderForm />}>
              <Route index element={<CustomerSelectionStep />} />
              <Route path={SalesOrderFormMenuKeys.HeaderInfo} element={<HeaderInfoStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryAddress} element={<DeliveryAddressStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryDetails} element={<DeliveryDetailsStep />} />
              <Route path={SalesOrderFormMenuKeys.ProductsSelection} element={<ProductsSelectionStep />}>
                <Route index element={<ProductsTable />} />
                {/* <Route path="search" element={<ProductSearchTab />} /> */}
              </Route>
              <Route path={SalesOrderFormMenuKeys.Overview} element={<OverviewStep />} />
            </Route>
            <Route path="/create" element={<SalesOrderForm />}>
              <Route index element={<CustomerSelectionStep />} />
              <Route path={SalesOrderFormMenuKeys.HeaderInfo} element={<HeaderInfoStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryAddress} element={<DeliveryAddressStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryDetails} element={<DeliveryDetailsStep />} />
              <Route path={SalesOrderFormMenuKeys.Attachments} element={<AttachmentsStep />} />
              <Route path={SalesOrderFormMenuKeys.ProductsSelection} element={<ProductsSelectionStep />}>
                <Route path="products" element={<ProductsTable />} />
                {/* <Route path="search" element={<ProductSearchTab />} /> */}
              </Route>
              <Route path={SalesOrderFormMenuKeys.Overview} element={<OverviewStep />} />
            </Route>
            <Route path="/posted/:id" element={<PostedSalesOrder />}>
              {/* <Route index element={<PostedSalesOrder />} /> */}
              {/* <Route index element={<CustomerSelectionStep />} />
              <Route path={SalesOrderFormMenuKeys.HeaderInfo} element={<HeaderInfoStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryAddress} element={<DeliveryAddressStep />} />
              <Route path={SalesOrderFormMenuKeys.DeliveryDetails} element={<DeliveryDetailsStep />} />
              <Route path={SalesOrderFormMenuKeys.Attachments} element={<AttachmentsStep />} />
              <Route path={SalesOrderFormMenuKeys.ProductsSelection} element={<ProductsSelectionStep />}>
                <Route index element={<SelectedProductsTable />} />
                <Route path="search" element={<ProductSearchTab />} />
              </Route> */}
              {/* <Route path={SalesOrderFormMenuKeys.Overview} element={<OverviewStep />} /> */}
            </Route>
          </Routes>
        </Content>
      </Layout>
    </ErrorBoundary>
  );
}

export default App;
