import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0ProviderWithHistory } from 'redux/auth0/Auth0-Provider';
import Rollbar from 'rollbar';
import App from './app/App';
import './common/styles/antd.customize.less';
import './common/styles/global.less';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  enabled: process.env.REACT_APP_ROLLBAR_ENABLED
};

const rollbar = new Rollbar(rollbarConfig);

// Upgraded to new root API from react 18
const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <Auth0ProviderWithHistory>
        <RollbarProvider instance={rollbar} config={rollbarConfig}>
          <ErrorBoundary>
              <App />
          </ErrorBoundary>
        </RollbarProvider>
      </Auth0ProviderWithHistory>
      </PersistGate>
    </ReduxProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
