import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  showBulkApply: boolean;
  bulkSiteValue?: string;
  bulkWarehouseValue?: string;
  bulkVendorValue?: string;
}

const initialState: InitialState = {
  showBulkApply: false
};

export const bulkApplySlice = createSlice({
  name: 'bulkApply',
  initialState,
  reducers: {
    setShowBulkApply: (state, { payload }: PayloadAction<InitialState['showBulkApply']>) => {
      state.showBulkApply = payload;
    },
    setBulkSiteValue: (state, { payload }: PayloadAction<InitialState['bulkSiteValue']>) => {
      state.bulkSiteValue = payload;
    },
    setBulkWarehouseValue: (state, { payload }: PayloadAction<InitialState['bulkWarehouseValue']>) => {
      state.bulkWarehouseValue = payload;
    },
    setBulkVendorValue: (state, { payload }: PayloadAction<InitialState['bulkVendorValue']>) => {
      state.bulkVendorValue = payload;
    }
  }
});

export const { setBulkSiteValue, setBulkVendorValue, setBulkWarehouseValue, setShowBulkApply } = bulkApplySlice.actions;
