import { Button, Space } from 'antd';
import { useFormikContext } from 'formik';
import { SalesOrderPayloadModel } from 'models/SalesOrderPayloadModel';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkSiteValue, setBulkVendorValue, setBulkWarehouseValue } from 'redux/productsInfo/bulkApplySlice';
import { ReduxState } from 'redux/rootReducer';
import { BulkSiteInput } from './BulkSiteInput';
import { BulkVendorInput } from './BulkVendorInput';
import { BulkWarehouseInput } from './BulkWarehouseInput';

export const BulkValueInputs: React.FC = () => {
  const { selectedLineItemIds } = useSelector((state: ReduxState) => state.productsInfo);
  const { bulkSiteValue, bulkVendorValue, bulkWarehouseValue, showBulkApply } = useSelector((state: ReduxState) => state.bulkApply);
  const { setValues, values } = useFormikContext<SalesOrderPayloadModel>();
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  const handleBulkApply = (): void => {
    setValues((prevValues) => ({
      ...prevValues,
      lines: prevValues.lines.map((item) =>
        selectedLineItemIds.includes(item.id) ? { ...item, siteId: bulkSiteValue || item.siteId, vendorId: bulkVendorValue || item.vendorId, warehouseId: bulkWarehouseValue || '' } : item
      )
    }));
    dispatch(setBulkSiteValue(undefined));
    dispatch(setBulkVendorValue(undefined));
    dispatch(setBulkWarehouseValue(undefined));
  };

  if (!showBulkApply) {
    return <></>;
  }

  return (
    <Space>
      <BulkVendorInput />
      <BulkSiteInput />
      <BulkWarehouseInput />
      <Button onClick={handleBulkApply} disabled={!(bulkSiteValue || bulkVendorValue || bulkWarehouseValue)} type="primary">
        Apply
      </Button>
    </Space>
  );
};
