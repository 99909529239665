import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  draftId: string;
}
const initialState: InitialState = {
  draftId: ''
};

export const salesOrderDraftIdSlice = createSlice({
  name: 'salesOrderDraftId',
  initialState,
  reducers: {
    setDraftId(state, action: PayloadAction<InitialState['draftId']>) {
      state.draftId = action.payload;
    }
  }
});

export const { setDraftId } = salesOrderDraftIdSlice.actions;
