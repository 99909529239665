import { useAuth0 } from '@auth0/auth0-react';
import { UserModel } from 'models/UserModel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAccessToken, setUser } from 'redux/app/app-slice';
import { ReduxState } from 'redux/rootReducer';

export const useAuthentication = (): { isLoading: boolean; accessToken: string | null } => {
  const { accessToken } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      if (user) {
        dispatch(setUser((user as unknown) as UserModel));
      }
      if (accessToken) {
        const payload = JSON.parse(window.atob(accessToken.split('.')[1]));

        const permissionsPayload = payload.permissions.filter((permission: string) => permission.split(':')[0] === 'useApplication' || permission.split(':')[0] === 'useApplciation');

        if (!permissionsPayload.includes(process.env.REACT_APP_APPLICATION_ACCESS_PERMISSION_NAME)) navigate('unauthorized');
      }
    }
  }, [user, accessToken, isAuthenticated]);

  return { isLoading, accessToken };
};
