import { Space } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterCategory, setFilterManufacturer, setFilterProductName, setFilterProductNumber } from 'redux/productsInfo/productsInfoSlice';
import { useDebouncedCallback } from 'use-debounce';

enum SearchType {
  PRODUCT_ID = 'productId',
  PRODUCT_NAME = 'productName',
  MANUFACTURER = 'manufacturerId',
  CATEGORY = 'productGroupId'
}

export const ProductsSearchFilters: React.FC = () => {
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  const debounceSearchValue = useDebouncedCallback((value: string, type: SearchType) => {
    switch (type) {
      case SearchType.PRODUCT_ID:
        dispatch(setFilterProductNumber(value));
        break;

      case SearchType.PRODUCT_NAME:
        dispatch(setFilterProductName(value));
        break;

      case SearchType.MANUFACTURER:
        dispatch(setFilterManufacturer(value));
        break;

      case SearchType.CATEGORY:
        dispatch(setFilterCategory(value));
        break;
    }
  }, 600);

  const handleSearchNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debounceSearchValue(e.currentTarget.value, SearchType.PRODUCT_NAME);
  };

  const handleSearchNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debounceSearchValue(e.currentTarget.value, SearchType.PRODUCT_ID);
  };

  const handleSearchManufacturerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debounceSearchValue(e.currentTarget.value, SearchType.MANUFACTURER);
  };

  const handleSearchCategoryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debounceSearchValue(e.currentTarget.value, SearchType.CATEGORY);
  };

  return (
    <Space>
      <div>Search Filters</div>
      <FancyInput placeholder="Product Number" width={150} onChange={handleSearchNumberChange} />
      <FancyInput placeholder="Product Name" width={150} onChange={handleSearchNameChange} />
      <FancyInput placeholder="Manufacturer" width={150} onChange={handleSearchManufacturerChange} />
      <FancyInput placeholder="Category" width={150} onChange={handleSearchCategoryChange} />
    </Space>
  );
};
