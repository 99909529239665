import { Button, Col, Row, Space, Typography } from 'antd';
import { DeleteButton } from 'components/atoms/DeleteButton';
import { PostButton } from 'components/atoms/PostButton';
import { SaveButton } from 'components/atoms/SaveButton';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesOrderPayloadModel } from 'models/SalesOrderPayloadModel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReduxState } from 'redux/rootReducer';
import { setDraftId } from 'redux/salesOrder/salesOrderDraftIdSlice';
import { useLazyGetSalesOrderQuery } from 'redux/services/useMamaBearService';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

interface Props {
  isFormValid: boolean;
}

export const SalesOrderFormHeaderInfo = ({ isFormValid }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { values } = useFormikContext<SalesOrderPayloadModel>();
  const { draftId } = useSelector((state: ReduxState) => state.salesOrderDraftId);
  const { id } = useParams();
  const { pathname } = useLocation();
  const [trigger, result] = useLazyGetSalesOrderQuery();

  const isPosted = pathname.includes('/posted');
  const isDraft = pathname.includes('/drafts');
  const isCreate = pathname.includes('/create');

  const showBackButton = isPosted || isDraft;

  useEffect(() => {
    if (isPosted && id && !result.data) {
      trigger({ id });
    }
  }, [id, trigger, result, isPosted]);

  useEffect(() => {
    return (): void => {
      dispatch(setDraftId(''));
    };
  }, []);

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Space size="large">
          {isPosted ? <div>{`SO Number: ${result.data?.salesOrderNumber}`}</div> : <div>Create Sales Order</div>}
          {values.customerId && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Customer: </Typography.Text>
              <Typography.Text>{values.customerId}</Typography.Text>
            </Space>
          )}
          {values.customerPo && (
            <Space>
              <Typography.Text style={styles.headerTitle}>PO Number: </Typography.Text>
              <Typography.Text>{values.customerPo}</Typography.Text>
            </Space>
          )}
          {draftId && !pathname.includes('/create') && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Draft Id: </Typography.Text>
              <Typography.Text>{draftId}</Typography.Text>
            </Space>
          )}
        </Space>
      </Col>
      <Col>
        <Space>
          <Link to="/">
            {showBackButton ? (
              <Button>Back to Sales Orders</Button>
            ) : (
              <Button danger ghost>
                Cancel
              </Button>
            )}
          </Link>
          {pathname.includes('/drafts/') && <DeleteButton areAllStepsCompleted />}
          <SaveButton />
          <PostButton areAllStepsCompleted={isFormValid} />
        </Space>
      </Col>
    </Row>
  );
};
